<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: '',
  },

  subtitle: {
    type: String,
    default: '',
  },

  show: {
    type: Boolean,
    default: false,
  },

  largeTitle: {
    type: Boolean,
    default: false,
  },

  noTitle: {
    type: Boolean,
    default: false,
  },

  overflowY: {
    type: Boolean,
    default: true,
  },

  half: {
    type: Boolean,
    default: false,
  },

  enterAnimate: {
    type: String,
    default: 'animate__animated animate__fadeInRight animate__faster',
  },
  leaveAnimate: {
    type: String,
    default: 'animate__animated animate__fadeOutRight animate__faster',
  },
})

const emit = defineEmits(['closeModal'])

const isVisible = ref(false)

function close() {
  isVisible.value = false

  setTimeout(() => {
    emit('closeModal')
  }, 300)
}

watch(
  () => props.show,
  state => state && setTimeout(() => (isVisible.value = props.show), 50),
)
</script>

<template>
  <teleport to="body">
    <div
      v-if="show"
      class="bg-neutral-900/10 fixed inset-0 z-50"
      @click="close"
    >
      <transition
        name="custom-classes-transition"
        :enter-active-class="enterAnimate"
        :leave-active-class="leaveAnimate"
        mode="out-in"
      >
        <section
          v-if="isVisible"
          :class="{
            'h-5/6 bottom-0 rounded-t-lg': half,
            'h-screen top-0': !half,
            'overflow-y-auto': overflowY,
            'overflow-y-hidden': !overflowY,
          }"
          class="fixed w-full xl:max-w-[660px] lg:right-0 bg-white z-50 shadow-2xl"
          @click.stop
        >
          <div
            v-if="!noTitle"
            class="h-[76px] bg-neutral-variant-87 py-4 px-8 flex items-end justify-between" :class="[
              {
                ['bg-gradient-to-b from-primary-70 via-primary-95 to-white px-0 flex items-center justify-center']: largeTitle,
              },
            ]"
          >
            <div class="w-full">
              <h3 class="text-xl pb-2 text-primary-20" :class="[{ ['text-primary-50 font-extrabold text-center w-full']: largeTitle }]">
                {{ title }}
              </h3>

              <p v-if="subtitle" class="font-bold text-primary-50 text-center">
                {{ subtitle }}
              </p>
            </div>

            <button
              aria-label="Botão para fechar o modal"
              class="w-12 h-12 rounded-full hover:bg-neutral-70 duration-300"
              type="button"
              @click="close"
            >
              <span class="sr-only">Botão para fechar modal</span>
              <Icon name="ic:round-close" class="w-6 h-6" :class="largeTitle ? 'text-primary-50' : 'text-primary-20'" />
            </button>
          </div>
          <div :class="`py-4 flex items-center gap-4 w-full ${noTitle ? 'h-full' : 'content-height'}`">
            <slot name="content" />
          </div>
        </section>
      </transition>
    </div>
  </teleport>
</template>

<style lang="postcss" scoped>
.content-height {
  height: calc(100% - 120px);
}
</style>
